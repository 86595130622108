import PropTypes from 'prop-types';
import { styled } from '@activebrands/core-web/libs/styletron';
import ThemeButton from 'components/buttons/ThemeButton';

const Buttons = styled('div', {
    display: 'flex',
    gap: '8px',
});

const SearchTypeFilterButtons = ({ $style = {}, buttonStyle = {}, current, items = [] }) =>
    items.length > 0 ? (
        <Buttons $style={$style}>
            {items.map(item => (
                <ThemeButton
                    $style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: '16px',
                        padding: '12px',
                        ...buttonStyle,
                    }}
                    active={current === item.id}
                    fontKeys="Misc/14_100_-1_500_UC"
                    key={item.id}
                    size="md"
                    theme="defaultsecondary"
                    onClick={() => item.onClick(item.id)}
                >
                    {item.label}
                    <span>{item.totalHits}</span>
                </ThemeButton>
            ))}
        </Buttons>
    ) : null;

SearchTypeFilterButtons.propTypes = {
    $style: PropTypes.object,
    buttonStyle: PropTypes.object,
    current: PropTypes.string,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            id: PropTypes.string,
            onClick: PropTypes.func,
        })
    ),
};

export default SearchTypeFilterButtons;
