import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import useSearchQuery from 'queries/useSearchQuery';
import media from '@activebrands/core-web/config/media';
import { useStateContext } from '@activebrands/core-web/contexts/StateContext';
import overlay from '@activebrands/core-web/libs/overlay';
import { styled } from '@activebrands/core-web/libs/styletron';
import { updateQueryParams } from '@activebrands/core-web/utils/query';
import debounce from '@grebban/utils/function/debounce';
import extractQueryParams from '@grebban/utils/string/extractQueryParams';
import { useLocation } from '@reach/router';
import ThemeButton from 'components/buttons/ThemeButton';
import SearchIcon from 'components/icons/SearchIcon';
import Input from 'components/inputs/Input';

const Form = styled('form', {
    display: 'flex',
    justifyContent: 'space-between',
    border: '1px solid var(--color-border-search-header)',
    borderRadius: '8px',

    [media.min['desktop.sm']]: {
        display: 'none',
    },
});

const OverlaySearchInput = ({ $style, placeholder }) => {
    const [state, setState] = useStateContext();
    const location = useLocation();
    const { searchPage } = useSearchQuery();

    const handleInputChange = debounce(({ target }) => {
        const query = target.value;
        setState(query);
    }, 500);

    // Navigate to search page and trigger a search there
    const onSubmit = e => {
        e.preventDefault();
        overlay.close('search');
        handleInputChange.cancel();

        let params = {
            q: e.target.elements[0].value,
        };

        // Keep all params except page if the search is performed on the searchpage
        if (location.pathname === searchPage) {
            const queryParams = extractQueryParams(location.search);
            delete queryParams.page;
            params = { ...queryParams, ...params };
        }

        // Update the http-query, this will trigger a search on the search page
        updateQueryParams(params, { basePath: searchPage });
    };

    return (
        <Form $style={$style} onSubmit={onSubmit}>
            <Input
                $style={{ marginTop: '0' }}
                inputStyle={{
                    width: '100%',
                    background: 'none',
                    height: 'auto',
                    border: 'none',
                    color: 'inherit',
                    outline: 'none',
                    lineHeight: 1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',

                    '::placeholder': {
                        color: 'var(--color-text-input)',
                    },
                }}
                inputFontKeys="Misc/14_100_500"
                maxLength="100"
                value={state}
                placeholder={placeholder}
                onChange={handleInputChange}
            />
            <ThemeButton
                $style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 'auto',
                    height: 'auto',
                    padding: ' 8px',
                    borderStyle: 'none',
                    backgroundColor: 'transparent',
                }}
                theme="inverted"
                type="submit"
            >
                <SearchIcon />
            </ThemeButton>
        </Form>
    );
};

OverlaySearchInput.propTypes = {
    $style: PropTypes.object,
    placeholder: PropTypes.string,
};

OverlaySearchInput.defaultProps = {
    placeholder: undefined,
};

export default OverlaySearchInput;
